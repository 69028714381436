<template>
  <div class="nav-management">
    <b-row>
      <b-col
        v-if="isSidebar"
        cols="4"
      >
        <b-card class="nav-management__card">
          <b-tabs v-model="tabActive">
            <template #tabs-end>
              <div
                class="d-flex align-items-center justify-content-end flex-grow-1"
                style="min-height: 38px"
              >
                <b-dropdown
                  class="nav-management__dropdown ml-1"
                  variant="outline-secondary"
                  split
                  :disabled="isMenuCreating || isItemCreating || isCombinationItemCreating"
                  size="sm"
                  right
                >
                  <template #button-content>
                    <div
                      id="button-create"
                      v-b-tooltip.hover.top="tabActive === 0 ? $t('tooltipCreateNewMenu') : $t('tooltipCreateNewItem')"
                      class="ml-n1 mr-n1 mt-n50 mb-n50 pl-1 pr-1 pt-50 pb-50"
                      @click="tabActive === 0 ? onClickCreateMenu() : onClickCreateMenuItem()"
                    >
                      <b-spinner
                        v-if="isMenuCreating || isItemCreating || isCombinationItemCreating"
                        small
                      />
                      <span v-else>+ {{ tabActive === 0 ? $t('Menu') : $t('Item') }}</span>
                    </div>
                    <tooltip-create-menu
                      v-if="tabActive === 0"
                      target="button-create"
                      triggers="hover"
                    />
                  </template>
                  <small class="pl-75">{{ $t('Create New') }}</small>
                  <template v-if="!isCreateMenuDisabledWithoutPlan && !isCreateMenuDisabledWithPlan">
                    <b-dropdown-item @click="onClickCreateMenu">
                      {{ $t('Menu') }}
                    </b-dropdown-item>
                    <b-dropdown-divider />
                  </template>
                  <b-dropdown-item @click="onClickCreateMenuItem">
                    {{ $t('Item') }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="onClickCreateCombinationMenuItem">
                    {{ $t('Combination Item') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <b-input-group class="input-group-merge mb-2">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchValue"
                :placeholder="$t('Search')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  v-if="searchValue.length"
                  icon="XIcon"
                  role="button"
                  @click="searchValue = ''"
                />
                <div
                  v-b-tooltip.hover.top="$t('Sort')"
                  class="d-flex ml-50 mr-50"
                >
                  <icon-az
                    v-if="isSortByName"
                    fill="currentColor"
                    role="button"
                    style="font-weight: 500"
                    @click.native="isSortByName = false"
                  />
                  <feather-icon
                    v-else
                    icon="CalendarIcon"
                    role="button"
                    @click="isSortByName = true"
                  />
                </div>
                <feather-icon
                  role="button"
                  :icon="isReverseOrder ? 'ArrowUpIcon' : 'ArrowDownIcon'"
                  @click="isReverseOrder = !isReverseOrder"
                />

              </b-input-group-append>
            </b-input-group>
            <b-tab
              :title="$t('Menus')"
              @click="onTabClick(0)"
            >
              <div class="nav-management__list">
                <div class="tree">
                  <div
                    v-for="(menu, menuIndex) in menusToRender"
                    :key="menu.id"
                  >
                    <div
                      :class="[
                        'tree__head',
                        {
                          'border-top': menuIndex !== 0,
                          'tree__head_active': $route.params.id === menu.id,
                        },
                      ]"
                    >
                      <b-link
                        :to="{ name: 'menu', params: { id: menu.id } }"
                        class="tree__link mr-50 font-weight-bold"
                      >
                        <feather-icon
                          icon="BookOpenIcon"
                          class="align-middle mr-50"
                        />
                        <span class="align-middle">{{ menu.name }}</span>
                      </b-link>
                      <div
                        v-if="menu.content.filter(i => i.type === 'Category').length"
                        v-b-toggle="menu.id"
                        :class="[
                          'tree__arrow',
                          $route.params.id === menu.id ? 'text-white' : 'text-primary',
                        ]"
                      >
                        <feather-icon icon="ChevronDownIcon" />
                      </div>
                    </div>
                    <b-collapse
                      v-if="menu.content.filter(i => i.type === 'Category').length"
                      :id="menu.id"
                      :visible="false"
                      class="tree__body"
                    >
                      <div
                        v-for="
                          category in menu.content
                            .filter(i => i.type === 'Category')
                            .map(i => menu.categories.find(j => j.id === i.id))
                        "
                        :key="category.id"
                      >
                        <div
                          v-b-toggle="category.id"
                          :class="[
                            'tree__head border-top',
                            { 'tree__head_active': $route.params.id === category.id },
                          ]"
                        >
                          <div class="mr-50 font-weight-bold">
                            <feather-icon
                              icon="ListIcon"
                              class="align-middle mr-50"
                            />
                            <span class="align-middle">{{ category.name }}</span>
                          </div>
                          <div
                            v-if="category.content.filter(i => i.type === 'Offer').length"
                            class="tree__arrow"
                          >
                            <feather-icon icon="ChevronDownIcon" />
                          </div>
                        </div>
                        <b-collapse
                          v-if="category.content.filter(i => i.type === 'Offer').length"
                          :id="category.id"
                          :visible="false"
                          class="tree__body"
                        >
                          <div
                            v-for="
                              item in category.content
                                .filter(i => i.type === 'Offer')
                                .map(i => ({
                                  ...category.items.find(j => j.id === i.id).item,
                                  offerId: i.id,
                                }))
                            "
                            :key="item.offerId"
                          >
                            <div
                              :class="[
                                'tree__head border-top',
                                { 'tree__head_active': $route.params.id === item.id }
                              ]"
                            >
                              <b-link
                                :to="{
                                  name: item.type === 'MenuItem' ? 'menu-item' : 'combination-menu-item',
                                  params: { id: item.id },
                                }"
                                class="tree__link mr-50"
                              >
                                <div class="tree__status">
                                  <feather-icon
                                    v-if="!item.isVisible"
                                    icon="EyeOffIcon"
                                    :class="[$route.params.id === item.id ? 'text-white' : 'text-primary']"
                                  />
                                  <icon-stop-list
                                    v-else-if="!item.isEnabled"
                                    :class="[$route.params.id === item.id ? 'fill-white' : 'fill-primary']"
                                  />
                                </div>
                                <span class="align-middle">{{ item.name }}</span>
                                <span
                                  v-if="item.type === 'CombinationMenuItem'"
                                  class="badge-output badge-output_border ml-50"
                                >combo</span>
                                <span
                                  v-if="item.badge"
                                  class="badge-output ml-50"
                                >{{ item.badge }}</span>
                              </b-link>
                              <div class="ml-auto text-primary">{{
                                category.items.find(i => i.id === item.offerId).price
                              }}</div>
                            </div>
                          </div>
                        </b-collapse>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab
              :title="$t('Items')"
              @click="onTabClick(1)"
            >
              <div class="nav-management__list">
                <div
                  v-for="(item, itemIndex) in itemsToRender"
                  :key="item.id"
                >
                  <div
                    :class="[
                      'tree__head',
                      { 'border-top': itemIndex !== 0 },
                    ]"
                  >
                    <b-link
                      :to="{
                        name: item.type === 'MenuItem' ? 'menu-item' : 'combination-menu-item',
                        params: { id: item.id },
                      }"
                      class="tree__link"
                    >
                      <div class="tree__status">
                        <feather-icon
                          v-if="!item.isVisible"
                          icon="EyeOffIcon"
                          :class="[$route.params.id === item.id ? 'text-white' : 'text-primary']"
                        />
                        <icon-stop-list
                          v-else-if="!item.isEnabled"
                          :class="[$route.params.id === item.id ? 'fill-white' : 'fill-primary']"
                        />
                      </div>
                      <span class="align-middle">{{ item.name }}</span>
                      <span
                        v-if="item.type === 'CombinationMenuItem'"
                        class="badge-output badge-output_border ml-50"
                      >combo</span>
                      <span
                        v-if="item.badge"
                        class="badge-output ml-50"
                      >{{ item.badge }}</span>
                    </b-link>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
      <b-col>
        <div
          v-if="$route.name === 'menu-management' && navHistoryToRender.length"
          class="nav-history"
        >
          <div class="nav-history__head">
            {{ $t('Recently viewed') }}:
          </div>
          <div class="nav-history__body">
            <router-link
              v-for="historyItem in navHistoryToRender"
              :key="historyItem.id"
              class="nav-history__item"
              :to="{ name: historyItem.routerLinkName, params: { id: historyItem.id } }"
            >
              {{ historyItem.name }}
            </router-link>
          </div>
        </div>
        <router-view
          @setSidebarState="isSidebar = $event"
          @setLastOpened="lastOpened[$event[0]] = $event[1]"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BTabs,
  BTab,
  BLink,
  BSpinner,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  VBToggle,
  VBTooltip,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import mixinNavManagementList from '@/mixins/nav-management-list'
import mixinTooltipCreateMenu from '@/mixins/tooltip/tooltip-create-menu'
import TooltipCreateMenu from '@/components/tooltip/TooltipCreateMenu.vue'
import IconStopList from '@/components/icon/IconStopList.vue'
import IconAz from '@/components/icon/IconAz.vue'

export default {
  name: 'MenuManagement',
  components: {
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BTabs,
    BTab,
    BLink,
    BSpinner,
    BCollapse,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    TooltipCreateMenu,
    IconStopList,
    IconAz,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [
    mixinNavManagementList,
    mixinTooltipCreateMenu,
  ],
  data() {
    return {
      isSidebar: true,
      tabActive: 0,
      searchValue: '',
      lastOpened: {
        menu: null,
        item: null,
      },

      isMenuCreating: false,
      isItemCreating: false,
      isCombinationItemCreating: false,

      isSortByName: false,
      isReverseOrder: false,
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      navHistory: 'main/navHistory',
      isMenusFetched: 'menuManagement/isMenusFetched',
      isMenuItemsFetched: 'menuManagement/isMenuItemsFetched',
      menus: 'menuManagement/menus',
      menuItems: 'menuManagement/menuItems',
    }),
    navHistoryToRender() {
      if (this.tabActive === 0) {
        return this.navHistory.menu
          .map(i => this.menus.find(j => j.id === i))
          .filter(i => !!i)
          .map(i => ({ ...i, routerLinkName: 'menu' }))
      }
      if (this.tabActive === 1) {
        return this.navHistory.item
          .map(i => this.menuItems.find(j => j.id === i))
          .filter(i => i)
          .map(i => ({ ...i, routerLinkName: i.type === 'MenuItem' ? 'menu-item' : 'combination-menu-item' }))
      }
      return []
    },
    isSearchValueValid() {
      return this.searchValue.length >= 2
    },
    menusToRender() {
      let list = [...this.menus]

      if (this.isSearchValueValid) {
        list = list.filter(i => i.name.toLowerCase().startsWith(this.searchValue.trim().toLowerCase()))
      }

      if (this.isSortByName) {
        list.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      } else {
        list.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      }

      if (this.isReverseOrder) {
        list.reverse()
      }

      return list
    },
    itemsToRender() {
      let list = [...this.menuItems]

      if (this.isSearchValueValid) {
        const itemsIds = list
          .filter(i => i.name.toLowerCase().startsWith(this.searchValue.trim().toLowerCase()))
          .map(i => i.id)

        const itemsIdsFromCategories = this.menus
          .flatMap(i => i.categories)
          .filter(i => i.name.toLowerCase().startsWith(this.searchValue.trim().toLowerCase()))
          .flatMap(i => i.items)
          .map(i => i.item.id)

        list = [...new Set([...itemsIds, ...itemsIdsFromCategories])]
          .map(id => list.find(item => item.id === id))
          .filter(i => !!i)
      }

      if (this.isSortByName) {
        list.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      } else {
        list.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      }

      if (this.isReverseOrder) {
        list.reverse()
      }

      return list
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newValue) {
        if (newValue.name === 'menu-management') {
          this.isSidebar = true
        }

        if (newValue.name === 'menu') {
          this.tabActive = 0
        }
        if (newValue.name === 'menu-item' || newValue.name === 'combination-menu-item') {
          this.tabActive = 1
        }
      },
    },
    restaurant() {
      this.fetch()
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    ...mapActions({
      getMenus: 'menuManagement/getMenus',
      getMenuItems: 'menuManagement/getMenuItems',
      setMenus: 'menuManagement/setMenus',
      setMenuItems: 'menuManagement/setMenuItems',
      createMenu: 'menuManagement/createMenu',
      createMenuItem: 'menuManagement/createMenuItem',
    }),
    async onClickCreateMenu() {
      if (this.isCreateMenuDisabledWithoutPlan || this.isCreateMenuDisabledWithPlan) {
        return
      }

      this.isMenuCreating = true

      const menu = await this.createMenu(this.$t('New Menu'))
      await this.setMenus([...this.menus, menu])

      await this.$router.push({
        name: 'menu',
        params: {
          id: menu.id,
          isFocusOnName: true,
        },
      })

      this.isMenuCreating = false
    },
    async createItem(isCreatingParam, itemName, itemType, routeNameToRedirect) {
      this[isCreatingParam] = true

      const menuItem = await this.createMenuItem([itemName, itemType, this.$t('Also consider ordering')])
      await this.setMenuItems([...this.menuItems, menuItem])

      await this.$router.push({
        name: routeNameToRedirect,
        params: {
          id: menuItem.id,
          isFocusOnName: true,
        },
      })

      this[isCreatingParam] = false
    },
    onClickCreateMenuItem() {
      this.createItem(
        'isItemCreating',
        this.$t('New Item'),
        'MenuItem',
        'menu-item',
      )
    },
    onClickCreateCombinationMenuItem() {
      this.createItem(
        'isCombinationItemCreating',
        this.$t('New Combination Item'),
        'CombinationMenuItem',
        'combination-menu-item',
      )
    },
    onTabClick(tabIndex) {
      if (tabIndex === 0) {
        if (this.lastOpened.menu !== null && this.menus.find(i => i.id === this.lastOpened.menu)) {
          if (!(this.$route.name === 'menu' && this.$route.params.id === this.lastOpened.menu)) {
            this.$router.push({
              name: 'menu',
              params: { id: this.lastOpened.menu },
            })
          }
        } else if (this.$route.name !== 'menu-management') {
          this.$router.push({ name: 'menu-management' })
        }
      }

      if (tabIndex === 1) {
        const item = this.menuItems.find(i => i.id === this.lastOpened.item)
        const itemPageName = item && item.type === 'MenuItem' ? 'menu-item' : 'combination-menu-item'
        if (this.lastOpened.item !== null && item) {
          if (!(this.$route.name === itemPageName && this.$route.params.id === this.lastOpened.item)) {
            this.$router.push({
              name: itemPageName,
              params: { id: this.lastOpened.item },
            })
          }
        } else if (this.$route.name !== 'menu-management') {
          this.$router.push({ name: 'menu-management' })
        }
      }
    },
    async fetch() {
      if (!this.restaurant) {
        return
      }

      let getActions = []

      if (!this.isMenusFetched) {
        getActions = [...getActions, 'getMenus']
      }
      if (!this.isMenuItemsFetched) {
        getActions = [...getActions, 'getMenuItems']
      }

      await Promise.all(getActions.map(getAction => this[getAction]()))
    },
  },
}
</script>

<style lang="sass">
  @import '@/assets/sass/tree.sass'
  @import '@/assets/sass/nav-history.sass'
</style>
